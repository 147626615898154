import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { PORTAL_API_URL } from '@/config';

export default {
  // getUserAccounts(ctx) {
  //   ctx.$store.commit('toggleLoadingPortalBirthdays');
  //   const params = {
  //     profiles:
  //       ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
  //         ctx.selectedProfileInMultiselectForRequest :
  //         [],
  //     // year: ctx.filter.year ? ctx.filter.year : '',
  //     month: ctx.filters.month ? ctx.filters.month : '',
  //     day: ctx.filters.day ? ctx.filters.day : ''
  //   };
  //
  //   // params.profiles = ctx.selectedProfile ? [ctx.selectedProfile.id] : [ctx.portalProfilesList[0].id];
  //
  //   Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/account`, { params }).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
  //         ctx.$store.commit('setPortalUserAccountsListByBirthday', response.data.data || []);
  //         if (!ctx.filters.day) {
  //           ctx.events = ctx.setEvents(response.data.data || []);
  //         }
  //         // ctx.totalAccountsResult = response.data.total;
  //         // if (ctx.$store.state.portalUserAccountsListByBirthday.length >= response.data.total) {
  //         //   ctx.canLoadMoreAccounts = false;
  //         // } else {
  //         //   ctx.canLoadMoreAccounts = true;
  //         // }
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalBirthdays');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalBirthdays');
  //     }
  //   );
  // },
  getUserAccounts(ctx, useLocationFilter = false) {
    ctx.$store.commit('toggleLoadingPortalBirthdays');
    const isUseLocationFilters = useLocationFilter
    const params = {
      profiles:
        ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
          ctx.selectedProfileInMultiselectForRequest :
          [],
      // year: ctx.filter.year ? ctx.filter.year : '',
      month: ctx.filters.month ? ctx.filters.month : '',
      day: ctx.filters.day ? ctx.filters.day : ''
    };
    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (isUseLocationFilters) {
      params.location_id = ctx.filters.location?.id || [];
      params.with_childs = ctx.filters.with_childs || false;
      params.location = ctx.filters.location?.id || [];
    }

    // params.profiles = ctx.selectedProfile ? [ctx.selectedProfile.id] : [ctx.portalProfilesList[0].id];

    Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/account`, { params }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
          ctx.$store.commit('setPortalUserAccountsListByBirthday', response.data.data || []);
          if (!ctx.filters.day) {
            ctx.events = ctx.setEvents(response.data.data || []);
          }
          // ctx.totalAccountsResult = response.data.total;
          // if (ctx.$store.state.portalUserAccountsListByBirthday.length >= response.data.total) {
          //   ctx.canLoadMoreAccounts = false;
          // } else {
          //   ctx.canLoadMoreAccounts = true;
          // }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalBirthdays');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalBirthdays');
      }
    );
  },
  // getUserAccountsForPortalDashBoardBirthdays(ctx) {
  //   ctx.$store.commit('portalBirthdaysForDashboardStartLoading');
  //   const params = {
  //     profiles:
  //       ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
  //         ctx.selectedProfileInMultiselectForRequest :
  //         [],
  //     // year: ctx.filters.year ? ctx.filters.year : '',
  //     month: ctx.filters.month ? ctx.filters.month : '',
  //     day: ''
  //   };
  //   Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/account`, { params }).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         ctx.$store.commit('portalBirthdaysForDashboardStopLoading');
  //         // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
  //         ctx.$store.commit('setPortalUserAccountsListByBirthday', response.data.data || []);
  //         ctx.events = ctx.setEvents(response.data.data || []);
  //       } else {
  //         ctx.$store.commit('portalBirthdaysForDashboardStopLoading');
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$emit('birthDaysLoaded');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('portalBirthdaysForDashboardStopLoading');
  //       ctx.$emit('birthDaysLoaded');
  //     }
  //   );
  // }
    getUserAccountsForPortalDashBoardBirthdays(ctx, useLocationFilter = false) {
    ctx.$store.commit('portalBirthdaysForDashboardStartLoading');
    const isUseLocationFilters = useLocationFilter
    const params = {
      profiles:
        ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
          ctx.selectedProfileInMultiselectForRequest :
          [],
      // year: ctx.filters.year ? ctx.filters.year : '',
      month: ctx.filters.month ? ctx.filters.month : '',
      day: ''
    };
    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (isUseLocationFilters) {
      let location = undefined;
      if (!ctx.filters.location || ctx.filters.location === 'All locations') {
        location = undefined
      } else {
        location = ctx.filters.location
      }
      params.location = location;
      params.location_id = location;
      params.with_childs = ctx.filters.with_childs || false;
    }
    Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/account`, { params }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.$store.commit('portalBirthdaysForDashboardStopLoading');
          // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
          ctx.$store.commit('setPortalUserAccountsListByBirthday', response.data.data || []);
          ctx.events = ctx.setEvents(response.data.data || []);
        } else {
          ctx.$store.commit('portalBirthdaysForDashboardStopLoading');
          VueNotifications.error({ message: response.data.description });
          ctx.$emit('birthDaysLoaded');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('portalBirthdaysForDashboardStopLoading');
        ctx.$emit('birthDaysLoaded');
      }
    );
  }
};
