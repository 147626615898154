var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.hide)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
    content: ("" + _vm.infoForTooltip),
    html: true
  }),expression:"{\n    content: `${infoForTooltip}`,\n    html: true\n  }",modifiers:{"top-center":true}}],staticClass:"numbers-difference-component-wrapper",class:{ 'numbers-difference-component-visability-hidden': _vm.visabilityHidden }},[_c('div',{staticClass:"text-center"},[(_vm.differenceObject.differenceDirection === 'loss')?_c('span',[_c('i',{staticClass:"fa fa-caret-down numbers-difference-component-loss",attrs:{"aria-hidden":"true"}})]):(_vm.differenceObject.differenceDirection === 'grow')?_c('span',[_c('i',{staticClass:"fa fa-caret-up numbers-difference-component-grow",attrs:{"aria-hidden":"true"}})]):(
        _vm.differenceObject.differenceDirection === 'none' &&
        _vm.showEqualIfDifferenceDirectionIsNoneAndHidePercentsEnabled &&
        _vm.hidePercents
      )?_c('span',[_c('i',{staticClass:"numbers-difference-component-none",attrs:{"aria-hidden":"true"}},[_vm._v("=")])]):_c('span',[_c('i',{staticClass:"fa fa-caret-left numbers-difference-component-none",class:{ 'numbers-difference-component-hide-none-arrow': true },attrs:{"aria-hidden":"true"}})])]),(this.hidePercents !== true)?_c('div',{staticClass:"numbers-difference-component-percents",class:{
      'numbers-difference-component-hide-percent': this.differenceObject.differencePercents === false,
      'numbers-difference-component-loss': this.differenceObject.differenceDirection === 'loss',
      'numbers-difference-component-grow': this.differenceObject.differenceDirection === 'grow',
      'numbers-difference-component-none': this.differenceObject.differenceDirection === 'none',
      'numbers-difference-component-percents-small':
        this.differenceObject.differencePercents && this.differenceObject.differencePercents > 999
    }},[_vm._v(" "+_vm._s(_vm.differenceObject.differencePercents)+"% ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }