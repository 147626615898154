<template>
  <div
    v-if="!this.hide"
    :class="{ 'numbers-difference-component-visability-hidden': visabilityHidden }"
    v-tooltip.top-center="{
      content: `${infoForTooltip}`,
      html: true
    }"
    class="numbers-difference-component-wrapper"
  >
    <div class="text-center">
      <span v-if="differenceObject.differenceDirection === 'loss'">
        <i class="fa fa-caret-down numbers-difference-component-loss" aria-hidden="true"></i>
      </span>
      <span v-else-if="differenceObject.differenceDirection === 'grow'">
        <i class="fa fa-caret-up numbers-difference-component-grow" aria-hidden="true"></i>
      </span>
      <span
        v-else-if="
          differenceObject.differenceDirection === 'none' &&
          showEqualIfDifferenceDirectionIsNoneAndHidePercentsEnabled &&
          hidePercents
        "
      >
        <i class="numbers-difference-component-none" aria-hidden="true">=</i>
      </span>
      <span v-else>
        <i
          class="fa fa-caret-left numbers-difference-component-none"
          aria-hidden="true"
          :class="{ 'numbers-difference-component-hide-none-arrow': true }"
        ></i>
      </span>
    </div>
    <div
      v-if="this.hidePercents !== true"
      :class="{
        'numbers-difference-component-hide-percent': this.differenceObject.differencePercents === false,
        'numbers-difference-component-loss': this.differenceObject.differenceDirection === 'loss',
        'numbers-difference-component-grow': this.differenceObject.differenceDirection === 'grow',
        'numbers-difference-component-none': this.differenceObject.differenceDirection === 'none',
        'numbers-difference-component-percents-small':
          this.differenceObject.differencePercents && this.differenceObject.differencePercents > 999
      }"
      class="numbers-difference-component-percents"
    >
      {{ differenceObject.differencePercents }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumbersDifference',
  props: {
    difference: Object,
    hidePercents: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    periodCaption: {
      type: String,
      default: ''
    },
    periodForCompareCaption: {
      type: String,
      default: ''
    },
    visabilityHidden: {
      type: Boolean,
      default: false
    },
    showEqualIfDifferenceDirectionIsNoneAndHidePercentsEnabled: {
      // если показываются проценты, то когда разницы между числами нет, показано 0%
      // а вот когда показ процентов выключен, то там остается пустое место
      // если же включить этот  пропс в true то прии равенстве будет показан значек "=" вместо
      // стрелок роста или падения
      type: Boolean,
      default: false
    }
  },
  computed: {
    differenceObject() {
      return this.difference;
    },
    infoForTooltip() {
      let tooltipString = `<div class="numbers-difference-component-tooltip">${this.periodCaptionForTooltip}: ${this.differenceObject.currentVal}<br>${this.periodForCompareCaptionForTooltip}: ${this.differenceObject.previousVal}<br>`;

      if (this.differenceObject.differencePercents !== false) {
        tooltipString += `<br>${this.$t('general.differenceBetweenPeriods')}: `;

        if (this.differenceObject.differenceDirection === 'loss') {
          tooltipString += `${this.$t('general.differenceLoss')} `;
        }
        if (this.differenceObject.differenceDirection === 'grow') {
          tooltipString += `${this.$t('general.differenceGrow')} `;
        }

        tooltipString += `${this.differenceObject.differencePercents}%</div>`;
      }

      return tooltipString;
    },
    periodCaptionForTooltip() {
      if (this.periodCaption !== '') {
        return this.periodCaption;
      }
      return this.$t('general.forCurrentPeriod');
    },
    periodForCompareCaptionForTooltip() {
      if (this.periodForCompareCaption !== '') {
        return this.periodForCompareCaption;
      }
      return this.$t('general.forPreviousPeriod');
    }
  }
};
</script>

<style lang="scss" scoped>
.numbers-difference-component-wrapper {
  display: inline-block;
  font-size: 0.7em;
  line-height: 0;
}
.numbers-difference-component-wrapper:before {
  content: '';
  display: inline-block;
}
.numbers-difference-component-loss {
  color: red;
}
.numbers-difference-component-grow {
  color: #24b122;
}
.numbers-difference-component-none {
  color: rgba(127, 127, 127, 0.52);
}
.numbers-difference-component-percents {
  font-size: 0.4em;
}
.numbers-difference-component-percents-small {
  font-size: 0.3em;
}
.numbers-difference-component-hide-percent {
  visibility: hidden;
}
.numbers-difference-component-hide-none-arrow {
  visibility: hidden;
}
.numbers-difference-component-visability-hidden {
  visibility: hidden;
}
</style>
<style lang="scss">
.numbers-difference-component-tooltip {
  text-align: left;
}
</style>
