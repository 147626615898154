<template>
  <div>
    <div class="card sticky-top">
      <div v-if="isPortalAdsLoading" class="loader loader-backdrop loader-backdrop-portalads" />
      <div class="card-block calendar-for-portal-dashboard">
        <calendar
          class=""
          :value="selectedDateOnCalendarNowDay"
          :pane="1"
          :has-input="false"
          :on-day-click="onDayClick"
          :first-day-of-week="1"
          :change-pane="changePaneCalendar"
        >
          <div v-for="(evt, index) in events" :slot="evt.id" :key="index" class="day-icon">
            <small>{{ evt.content }}</small>
          </div>
        </calendar>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// import Calendar from 'vue2-datepicker';
// import { Multiselect } from 'vue-multiselect';
import Calendar from 'vue2-slot-calendar';

// import EmptyCellValue from '../../components/empty-cell-value.vue';
import helpers from '../../helpers';
// import portalProfilesService from '../../services/portalProfilesService';
import portalBirthdayService from '../../services/portalBirthdaysService';
// import portalUserAccountsService from '../../services/portalUserAccountsService';

// const watcher = new helpers.ChangesWatcher();

export default {
  name: 'PortalBirthdaysForPortalDashboard.',
  components: {
    Calendar
  },
  data() {
    return {
      yearOnCalendar: null,
      // // основываясь на состоянии этого объекта отправляем событие об окончании загрузки родительскому
      // loadingBirthdays: {
      //   loadedPortalBirthdays: false,
      //   calculatedPortalBirthdays: false
      // },
      events: [],
      selectedDateOnCalendarNowDay: new Date(),
      selectedDateOnCalendar: new Date(),
      selectedProfile: { name: this.$t('portalStats.allProfiles'), id: '' },
      isPortalAdsLoading: false,
      filters: {
        profiles: false,
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        location: 'All locations',
        with_childs: true,

      }
    };
  },
  props: {
    location: {default: 'All locations'},
    with_childs: {default: true}
  },
  watch: {
    // // тут отсылаем событие в родителя, когда данные готовы к показу
    // loadingBirthdays: {
    //   deep: true,
    //   handler() {
    //     if (this.loadingBirthdays.loadedPortalBirthdays === true && this.loadingBirthdays.calculatedPortalBirthdays === true) {
    //       this.$emit('birthDaysLoaded');
    //     }
    //   }
    // },
    // // тут следим за статусом запросов  загрузки дней рождений
    // loadingPortalBirthdaysForDashboardStatus(newVal, oldVal) {
    //   if (newVal === false && oldVal === true) {
    //     // this.$emit('birthDaysLoaded');
    //     this.loadingBirthdays.loadedPortalBirthdays = true;
    //   }
    // },
    // // тут следим чтобы данные для календаря были подготовленными
    // userAccountsListByBirthdayForCalendarDisplayLength(newVal) {
    //   if (newVal === this.userAccountsListByBirthdayLength) {
    //     this.loadingBirthdays.calculatedPortalBirthdays = true;
    //   }
    // }
  },
  methods: {
    onDayClick() {
      // this.selectedDateOnCalendar = date;
      // this.filters.day = date.getDate();
      // // this.filters.month = date.getMonth() + 1;
    },
    // getUsersBirthdays() {
    //   // this.loadingBirthdays.calculatedPortalBirthdays = false;
    //   // this.loadingBirthdays.loadedPortalBirthdays = false;
    //   this.$emit('birthDaysLoading');
    //   portalBirthdayService.getUserAccountsForPortalDashBoardBirthdays(this);
    // },
    getUsersBirthdays() {
      // this.loadingBirthdays.calculatedPortalBirthdays = false;
      // this.loadingBirthdays.loadedPortalBirthdays = false;
      this.$emit('birthDaysLoading');
      portalBirthdayService.getUserAccountsForPortalDashBoardBirthdays(this, true);
    },
    // setFilterProfile(profile) {
    //   this.filters.profiles = profile;
    // },
    changePaneCalendar(year, month, pane) {
      this.selectedDateOnCalendar = new Date(year, month);
      this.filters.day = '';
      this.filters.month = month + 1;
      this.yearOnCalendar = year;
      // this.filters.year = year;
      this.getUsersBirthdays();
      // console.info(year, month);
    },
    /**
     * Используется в сервисе
     */
    setEvents(users) {
      // const users = this.$store.state.portalUserAccountsListByBirthday || [];
      const allEvents = users.map((user) => {
        const { month, day } = user.date_of_birth_struct;
        let year;
        if (this.yearOnCalendar === null) {
          year = new Date().getFullYear();
        } else {
          year = this.yearOnCalendar;
        }
        return {
          id: moment(new Date(year, month - 1, day)).format('MM/DD/YYYY')
        };
      });
      const evt = this.getCountIds(allEvents);
      this.$emit('birthDaysLoaded');
      return evt;
    },
    getCountIds(target) {
      const result = {};

      target.forEach((item) => (result[item.id] ? result[item.id]++ : (result[item.id] = 1)));

      return Object.keys(result).map((item) => ({
        id: item,
        content: result[item]
      }));
    }
  },

  computed: {
    // userAccountsListByBirthdayLength() {
    //   return this.userAccountsListByBirthday.length;
    // },
    // userAccountsListByBirthdayForCalendarDisplayLength() {
    //   let counter = 0;
    //   for (const event of this.events) {
    //     counter = counter + event.content;
    //   }
    //   return counter;
    //   // return this.events.length;
    // },
    // loadingPortalBirthdaysForDashboardStatus() {
    //   return this.$store.state.activeRequestsForPortalDashboardPage.loadingPortalBirthdaysForDashboard;
    // },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    userAccountsListByBirthday() {
      return this.$store.state.portalUserAccountsListByBirthday;
    },

    userAccountsList() {
      return this.$store.state.portalUserAccountsList;
    }
  },
  // created() {
  //   // ставим локаль календаря
  //   helpers.setBirthdaysCalendarLocale();
  //   // загружаем данные по ДР
  //   this.getUsersBirthdays();
  // },
  created() {
    // ставим локаль календаря
    helpers.setBirthdaysCalendarLocale();

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      // let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      // let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      // if (!locationForFiltering || locationForFiltering === '') {
      //   // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
      //   locationForFiltering = 'All locations';
      // }
      // if (locationForFiltering === 'All locations') {
      //   this.filters.location = {};
      //   this.filters.with_childs = true;
      // } else {
      //   this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      //   this.filters.with_childs = withChildsForFiltering
      // }
      if (!this.location || this.location === 'All locations' ) {
        this.filters.location = 'All locations';
      } else {
        this.filters.location = this.location;
      }
      this.filters.with_childs = this.with_childs;
    }


    // загружаем данные по ДР
    this.getUsersBirthdays();
  },
  mounted() {
    // this.getUsersBirthdays();
  }
};
</script>

<style lang="scss" scoped>
.mx-datepicker {
  width: 100% !important;
}
.mx-datepicker-body {
  display: flex;
  justify-content: center;
}

.img-thumbnail {
  display: block;
  max-width: 200px;
  border: 0;
}

.video-thumbnail {
  max-width: 100%;
  height: 100%;
}

.ads-schedule {
  margin: 0;
  padding: 0;

  list-style: none;
}

.ads-schedule__status--active {
  color: #4dbd74;
}

.ads-schedule__status--planned {
  color: #f8cb00;
}

.ads-schedule__status--done {
  color: #94a0b2;
}

.ads-schedule .card-header {
  flex-wrap: wrap;

  font-size: 0.875rem;
}

.ads-schedule .card-block {
  padding: 0.75rem 1.25rem;
}

.modal {
  // 0.4 - scaling const for iphone
  // 0.3 - scaling const for macbook

  .half-width {
    width: 46%;
  }
  .upload {
    line-height: 1.25;
  }
  .text-muted {
    text-overflow: ellipsis;
  }

  .interface-block {
    border-radius: 10px;
    margin: 0 1rem;
    padding: 1.5rem;
    background: rgba(236, 236, 236, 0.45);
  }
}

.custom-control {
  display: block;
}
</style>

<style lang="scss">
.datepicker {
  display: flex;
  justify-content: center;
}
.datepicker-wrapper {
  z-index: 0;
  width: 251px !important;
}
.datepicker-inner {
  width: 251px !important;
}
.datepicker-popup {
  z-index: 0;
  position: relative !important;
  border: 0 solid #fff;
  border-radius: 0;
  background: #fff;
  margin-top: 0px;
  box-shadow: none;
}
.datepicker-ctrl p,
.datepicker-ctrl span,
.datepicker-body span {
  display: inline-block;
  width: 33px;
  line-height: 33px;
  height: 33px;
  border-radius: 0;
}
.day-cell div {
  position: relative;
}
.day-cell div div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.day-icon {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background-color: #ffa538;
  border-radius: 50%;
  font-weight: 800;
  font-size: 10px;
  width: 12px;
  line-height: 12px;
  height: 12px;
}
.day-icon small {
  font-weight: bold;
}
.datepicker-dateRange-item-active:hover,
.datepicker-dateRange-item-active {
  background: #20a8d8 !important;
  color: white !important;
}

.loader-backdrop-portalads:after {
  position: absolute;
  top: 30px;
}
</style>

<style lang="scss" scoped>
table tr {
  cursor: default;
}
.submit {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}
.loader-all-page-loading:after {
  position: absolute;
  top: 40vh;
}
.star {
  font-size: 1em !important;
  color: #ffa602 !important;
}

.days-of-week .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.time-of-day .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.platform .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.schedule-caption-in-ad {
  clear: both;
}

.cool-modern-selector input {
  display: none !important;
}

.cool-modern-selector input[type='checkbox'] + label {
  display: inline-block;
  padding-right: 20px;
  border-radius: 6px;
  background: #dddddd;
  color: #939393;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  user-select: none;
}

.cool-modern-selector input[type='checkbox']:checked + label {
  background: #4dbd74;
  color: #ffffff;
}
.modal-info-in-ads {
  min-width: 650px;
}
.views-statistic {
  font-size: 0.9em;
}

.fix-overflowed-table {
  word-break: break-all;
  word-wrap: break-word;
}
.calendar-for-portal-dashboard {
  /*ставим 1 чтобы показать минимальный размер*/
  height: 1px;
  overflow: visible;
}
</style>

<!-- NOTE: icons from http://www.iconarchive.com/show/free-multimedia-icons-by-designbolts/Photo-icon.html -->
